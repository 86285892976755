import img1 from "./images/QR_Code_Jup.png";
import img2 from "./images/QR_Code_PhonePe.png";

export const books = [
  {
    author: "Rahul Luthra",
    title: "+919700486486 9700486486@jupiteraxis",
    img: img1,
    id: 1,
  },
  {
    author: "Rahul Luthra",
    title: "+919717740080 PhonePe",
    img: img2,
    id: 2,
  },
];
